import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/SEO";

function BlogPage() {
  return (
    <>
      <SEO
        title="Læs om gode råd eller find de bedste priser her på Billigforms blog"
        description="Det behøver ikke at være dyrt, at komme i form."
      />
      <div>
        <ul className="mt-12">
          <li className="my-2">
            <Link className="text-3xl" to="/blog/kom-nemmere-op-paa-titusinde-skridt">
              Kom nemmere op på 10.000 skridt
              <br />
            </Link>
          </li>
          <li className="my-20">
            <Link className="text-3xl" to="/blog/ro-dig-i-form-med-odin-r900">
              Ro dig i form med en Odin R900
              <br />
            </Link>
          </li>
          <li className="my-20">
            <Link
              className="text-3xl"
              to="/blog/kom-i-form-paa-en-crosstrainer"
            >
              Kom i form på en crosstrainer derhjemme
              <br />
            </Link>
          </li>
          <li className="my-20">
            <Link className="text-3xl" to="/blog/øvelser-med-exertube">
              Øvelser med exertube
              <br />
            </Link>
          </li>
          <li className="my-20">
            <Link className="text-3xl" to="/blog/bedste-protein-pandekager">
              Vores anbefalinger af protein pandekager
              <br />
            </Link>
          </li>
          <li className="my-20">
            <Link className="text-3xl" to="/blog/spar-penge-paa-fitnessgulvet">
              Spar penge på fitnessgulvet
              <br />
            </Link>
          </li>
          <li>
            <Link
              className="text-3xl"
              to="/blog/tre-tips-til-at-oege-muskelmassen"
            >
              3 tips til at øge muskelmassen
              <br />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default BlogPage;
